/** Return site url or root url for use in creating complete links across the site */
export const getHomePageUrl = (fullPath?: boolean) => {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview') {
    if (fullPath) {
      return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
    } else {
      return ``;
    }
  }

  if (process.env.NODE_ENV === 'development') {
    return `${process.env.NEXT_PUBLIC_SITE_URL}`;
  }

  return `https://${process.env.NEXT_PUBLIC_SITE_URL}`;
};

/** Return full url when passed slug */
export function getFullUrl(slug: string): string {
  const siteURL = getHomePageUrl();
  return `${siteURL}/${slug}`;
}

/** Return full url when passed slug */
export function getArticleUrl(slug: string) {
  const siteURL = getHomePageUrl();
  return `${siteURL}/blog/${slug}`;
}

export function getArticlePath(slug: string) {
  return `/blog/${slug}`;
}

/** Return full url when passed slug */
export function getProductUrl(categorySlug: string, productSlug: string) {
  const siteURL = getHomePageUrl();

  return `${siteURL}/shop/${categorySlug}/${productSlug}`;
}

export function getProductPath(categorySlug: string, productSlug: string) {
  return `/shop/${categorySlug}/${productSlug}`;
}

/** Return category url when passed slug */
export function getCategoryUrl(slug: string) {
  const siteURL = getHomePageUrl();
  return `${siteURL}/shop/${slug}`;
}

export function getCategoryPath(slug: string) {
  return `/shop/${slug}`;
}
